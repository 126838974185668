



































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  DefaBaseButton,
  DefaBaseLinkExternal,
  DefaLoaderCircle,
} from "@defa-as/components";
import { userModule } from "@/store/modules/user";
import { ROUTE_NAMES } from "@/router/route-names";
import { generateGDPRDocument } from "@/http/requests/requests-user";

@Component({
  components: { DefaBaseLinkExternal, DefaBaseButton, DefaLoaderCircle },
})
export default class ViewGDPRDocumentConfirmation extends Vue {
  @Prop({ default: "" }) readonly gdprDocumentUpdated!: string;
  loadingGDPRDocumentSign = false;
  loadingGDPRDocumentLink = false;
  gdprDocumentLink = "";

  async created() {
    this.loadingGDPRDocumentLink = true;
    try {
      const response = await generateGDPRDocument();
      this.gdprDocumentLink = response.documentUrl;
    } finally {
      this.loadingGDPRDocumentLink = false;
    }
  }

  get userModule() {
    return userModule;
  }

  get gdprDocumentUpdatedAsBoolean() {
    return this.gdprDocumentUpdated === "true";
  }

  get gdprDocumentConfirmationParagraphPath() {
    return this.gdprDocumentUpdatedAsBoolean
      ? "gdprDocumentConfirmation.paragraphUpdated"
      : "gdprDocumentConfirmation.paragraph";
  }

  async signGDPRDocument() {
    this.loadingGDPRDocumentSign = true;
    try {
      await userModule.signGDPRDocument();
      const redirectTo = this.$route.query["redirect-to"];
      await this.$router.replace(
        typeof redirectTo === "string"
          ? redirectTo
          : {
              name: ROUTE_NAMES.ROOT,
            }
      );
    } finally {
      this.loadingGDPRDocumentSign = false;
    }
  }
}
